import { Link } from "gatsby";
import classnames from "classnames";
import twitter from "../../images/twitter-white.svg";
import instagram from "../../images/instagram-white.svg";
import facebook from "../../images/facebook-white.svg";
import youtube from "../../images/youtube-white.svg";
import logoWhite from "../../images/logo-white.svg";
import React, { Fragment, useState, useContext } from "react";
import { domain } from "../../utils";
import { GlobalStateContext } from "../../context/GlobalContextProvider";

export const Navbar = ({ header, translateSlug }) => {
  const state = useContext(GlobalStateContext);
  const { language } = state;

  const { logo, links } = header;

  const [isActive, toggleState] = useState(false);
  const toggleMenu = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    toggleState(!isActive);
  };

  return (
    <Fragment>
      <nav>
        <div className={classnames("nav", isActive ? "open" : "")}>
          <div className="container-fluid py-3 py-md-4">
            <div className="row m-auto align-items-center">
              <div className="col-1 col-md-1 offset-md-1 order-1 p-0">
                <Link to={`/${language}/`}>
                  <img
                    className="logo"
                    src={`${domain}${logo.url}`}
                    alt="Coopz Logo"
                    width="auto"
                  />
                </Link>
              </div>
              <div className="col-1 col-md-8 order-md-2 order-3 p-0 text-right">
                <ul className="d-none d-md-inline pt-1 float-right m-0">
                  {links.map(({ label, slug }, index) => {
                    return (
                      <li key={index} className="d-inline">
                        <h6 className="d-inline">
                          <Link
                            to={`/${language}/${slug}/`}
                            className="text-uppercase m-2 m-md-2 m-lg-3 text-decoration-none"
                          >
                            {label}
                          </Link>
                        </h6>
                      </li>
                    )
                  })}
                </ul>
                <button
                  className={"btn d-md-none pr-0 m-0 nav-icon"}
                  type="button"
                  onClick={toggleMenu}
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>

                <div
                  className={classnames(
                    "nav-push-modal",
                    isActive ? "is-active" : ""
                  )}
                >
                  <div className={`nav-push`} data-push="true">
                    <div className="nav-content">
                      <div className="text-left">
                        <img className="push-logo" src={logoWhite} alt="coopz" />
                      </div>
                      <ul className="d-md-none">
                        <li className="mb-2 text-center">
                          <Link
                            to={`/${language}`}
                            className="text-uppercase text-decoration-none"
                          >
                            HOME
                          </Link>
                        </li>
                        {links.map(({ label, slug }, index) => {
                          return (
                            <li key={index} className="mb-2 text-center">
                              <Link
                                to={`/${language}/${slug}/`}
                                className="text-uppercase text-decoration-none"
                              >
                                {label}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                      <div>
                        <div className="text-center social-media mt-5">
                          <div>
                            <a
                              className="mx-2 my-btn-social-media"
                              href="https://twitter.com/coopzapp"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="twitter" src={twitter} />
                            </a>
                            <a
                              className="mx-2 my-btn-social-media"
                              href="https://www.instagram.com/coopzapp/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="instagram" src={instagram} />
                            </a>
                            <a
                              className="mx-2 my-btn-social-media"
                              href="https://www.facebook.com/coopzapp"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="facebook" src={facebook} />
                            </a>
                            <a
                              className="mx-2 my-btn-social-media"
                              href="https://www.youtube.com/channel/UCxjZkRE9Ne2iDGFhtOqYqFg"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="youtube" src={youtube} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-10 col-md-1 order-md-3 order-2 p-md-0 mt-1 text-right"
              >
                <Link
                  className="lang-font-size"
                  to={`/de/`}
                >
                  DE
                </Link>
                <Link
                  className="lang-font-size ml-2"
                  to={`/en/`}
                >
                  EN
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}
